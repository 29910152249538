
const awsmobile = {
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_appsync_graphqlEndpoint: "https://wpb7ozoj6zdqtok3wt3frt4wye.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_cloud_logic_custom: [],
    aws_cognito_region: "us-east-1",
    aws_project_region: "us-east-1",
    aws_user_files_s3_bucket: "evap-myeventsapp-photos",
    aws_user_files_s3_bucket_region: "us-east-1",
    aws_user_pools_id: "us-east-1_xUGQ0uOr3",
    aws_user_pools_web_client_id: "7pjielriscfk4km0ut1nbt56j2"
};

export default awsmobile;
